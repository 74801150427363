import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { userStore } from '@/store/modules/user/user-store';
import messageService from '@/services/message-service';
import payConfim from '@/components/pay/pay-confirm/index';

/**
 * meta所有属性
 * menuKey：string 用户个人中心，左侧菜单，使用的就是子路由的方式实现的，这个key就是给菜单选中用的
 * needLogin：boolean  是否需要登录，在路由钩子里会做相关判断
 * header: module | custom | 默认 页面头部菜单显示，目前支持模块（抠图等功能）和自定义，除开这两个就是默认的头部。
 * headerTitle： string 头部显示标题
 * hideHeaderUser：boolean 头部是否隐藏右侧用户模块
 * hideFooter：boolean 是否隐藏底部模块
 *
 * webpackChunkName说明
 * 所有路由组件配置需要使用按需引入方式，公共的可以不配置，如首页等，然后子功能需要单独配置一个webpackChunkName。具体参考之前的写法
 *
 * 钩子说明
 * 目前路由钩子做了两件事，一个是把一些全局的弹窗在路由变化时隐藏，如loading。还有一个就是是否需要登录的判断
 */

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/contact-us/contact-us.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/user.vue'),
    redirect: {
      name: 'user_bill',
    },
    children: [
      {
        path: 'bill',
        name: 'user_bill',
        component: () => import('@/views/user/bill/bill.vue'),
        meta: {
          menuKey: 'bill',
          needLogin: true,
        },
      },
      {
        path: 'order-list',
        name: 'user_order_list',
        component: () => import('@/views/user/order-list/order-list.vue'),
        meta: {
          menuKey: 'order_list',
          needLogin: true,
        },
      },
      {
        path: 'order-detail/:id',
        name: 'user_order_detail',
        component: () => import('@/views/user/order-detail/order-detail.vue'),
        meta: {
          menuKey: 'order_detail',
          needLogin: true,
        },
      },
      {
        path: 'account-setting',
        name: 'user_account_setting',
        component: () => import('@/views/user/account-setting/account-setting.vue'),
        meta: {
          menuKey: 'account_setting',
          needLogin: true,
        },
      },
    ],
  },
  {
    path: '/idphoto/home',
    name: 'idphoto_home',
    component: () => import(/* webpackChunkName: "idphoto" */ '@/views/idphoto/home/home.vue'),
  },
  {
    path: '/idphoto/gooos-home',
    name: 'idphoto_goods_home',
    component: () =>
      import(/* webpackChunkName: "idphoto" */ '@/views/idphoto/goods-home/goods-home.vue'),
    meta: {
      header: 'module',
      headerTitle: '证件照',
    },
  },
  {
    path: '/idphoto/upload/:goodsId',
    name: 'idphoto_upload',
    component: () => import(/* webpackChunkName: "idphoto" */ '@/views/idphoto/upload/upload.vue'),
    meta: {
      header: 'module',
      headerTitle: '证件照',
    },
  },

  {
    path: '/modules/ai-photo/home',
    name: 'ai_photo_home',
    component: () => import(/* webpackChunkName: "modules" */ '@/views/modules/ai-photo/home.vue'),
  },

  {
    path: '/modules/idphoto_photo_api',
    name: 'idphoto_photo_api',
    component: () =>
      import(
        /* webpackChunkName: "modules" */ '@/views/modules/api/idphoto-photo-api/idphoto-photo-api.vue'
      ),
  },
  {
    path: '/modules/ai_photo_api',
    name: 'ai_photo_api',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/api/ai-photo-api/ai-photo-api.vue'),
  },

  {
    path: '/modules/cutout/home',
    name: 'modules_cutout_home',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/cutout/home/home.vue'),
  },

  {
    path: '/modules/cutout/handler',
    name: 'modules_cutout_handler',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/cutout/handler/handler.vue'),
    meta: {
      header: 'module',
      headerTitle: '智能抠物',
      hideHeaderUser: true,
      hideFooter: true,
    },
  },

  {
    path: '/modules/portrait/home',
    name: 'modules_portrait_home',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/portrait/home/home.vue'),
  },

  {
    path: '/modules/portrait/handler',
    name: 'modules_portrait_handler',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/portrait/handler/handler.vue'),
    meta: {
      header: 'module',
      headerTitle: '一键抠人像',
      hideHeaderUser: true,
      hideFooter: true,
    },
  },

  {
    path: '/modules/watermark/home',
    name: 'modules_watermark_home',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/watermark/home/home.vue'),
  },

  {
    path: '/modules/watermark/handler',
    name: 'modules_watermark_handler',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/watermark/handler/handler.vue'),
    meta: {
      header: 'module',
      headerTitle: '在线去水印',
      hideHeaderUser: true,
      hideFooter: true,
    },
  },

  {
    path: '/modules/face-enhance/home',
    name: 'modules_face_enhance_home',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/face-enhance/home/home.vue'),
  },

  {
    path: '/modules/face-enhance/handler',
    name: 'modules_face-enhance_handler',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/face-enhance/handler/handler.vue'),
    meta: {
      header: 'module',
      headerTitle: '人脸变清晰',
      hideHeaderUser: true,
      hideFooter: true,
    },
  },

  {
    path: '/modules/erase/home',
    name: 'modules_erase_home',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/erase/home/home.vue'),
  },

  {
    path: '/modules/erase/handler',
    name: 'modules_erase_handler',
    component: () =>
      import(/* webpackChunkName: "modules" */ '@/views/modules/erase/handler/handler.vue'),
    meta: {
      header: 'module',
      headerTitle: '魔法擦除笔',
      hideHeaderUser: true,
      hideFooter: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  // 跳转页面，隐藏loading
  if (to.name !== from.name) {
    messageService.hideLoading();
    payConfim.unmountInstance();
  }

  if (to.meta?.needLogin && !userStore.getters.isLogin) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
