import { globalStore } from '@/store/modules/global/global-store';
import { userStore } from '@/store/modules/user/user-store';
import userService from './user-service';
import payConfirm from '@/components/pay/pay-confirm/index';
import eventTrack from '@/event-track';
export default {
  // 充值
  showRechargeDialog(options?: { category_id?: number }) {
    return new Promise((resolve, reject) => {
      if (options?.category_id) {
        eventTrack.show_recharge_by_point_lack({ category_id: options.category_id });
      } else {
        eventTrack.show_recharge_by_initiative();
      }
      globalStore.commit('showRechargeDialog', {
        resolve: resolve,
        reject: reject,
      });
    });
  },

  // 支付之前的校验,主要校验点数和登录
  async prePay(data: {
    price: number;
    category_id: number;
    showPayConfirm?: boolean;
  }): Promise<void> {
    if (!userStore.getters.isLogin) {
      // 未登录，校验不通过，然后拉起登录窗
      userService.login();
      return Promise.reject();
    }

    // 需要支付确认，就弹出这个弹窗
    if (data.showPayConfirm) {
      await payConfirm.show(data.price, data.category_id);
    } else {
      // 更新余额
      await userStore.dispatch('getUserInfo');
    }

    const currPoint = userStore.state.userInfo?.total_point || 0;

    if (data.price > currPoint) {
      // 点数不够，显示充值弹窗
      this.showRechargeDialog({ category_id: data.category_id });
      return Promise.reject();
    }
    return;
  },
};
