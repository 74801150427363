import { userStore } from '@/store/modules/user/user-store';
import { globalStore } from '@/store/modules/global/global-store';

export default {
  login(
    options: { openLoginDialog?: boolean; refresh?: boolean } = {
      openLoginDialog: true,
      refresh: false,
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      Promise.resolve(
        !options.refresh ? userStore.dispatch('userLoginFromCache') : Promise.reject()
      )
        .then(() => {
          resolve();
        })
        .catch(() => {
          if (options.openLoginDialog) {
            this.loginFromDialog()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        });
    });
  },

  loginFromDialog(): Promise<void> {
    return new Promise((resolve, reject) => {
      globalStore.commit('showLoginDialog', {
        page: 1,
        resolve: resolve,
        reject: reject,
      });
    });
  },
};
