import { ref } from 'vue';

export default {
  // 记录最后点击的元素，用于弹窗的时候的定位位置
  lastClickEl: ref<undefined | HTMLElement>(undefined),
  initRecordClickElement() {
    document.addEventListener('click', (e) => {
      this.lastClickEl.value = e.target as HTMLElement;
    });
  },
};
