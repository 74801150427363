// 功能分类
export const categoryMap: Record<string | number, string> = {
  1: '证件照',
  2: '智能抠物',
  3: '一键抠人像',
  4: '在线去水印',
  5: '魔法擦除笔',
  6: '人脸变清晰',
};

// 所有协议链接
export const agreementMap = {
  privacy: 'https://o.666visa.cn/privacy-policy-terms-of-use/pbs-privacy-policy.html',
  user: 'https://o.666visa.cn/privacy-policy-terms-of-use/pbs-term-of-use.html',
  subscribe: 'https://o.666visa.cn/privacy-policy-terms-of-use/pbs-auto-subscribe.html',
};

export const suffixToMimeMap: Record<string, string> = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  webp: 'image/webp',
  tiff: 'image/tiff',
  bmp: 'image/bmp',
};
