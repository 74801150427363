import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from 'vue';
import { IUserState } from './user-type';

import { mutations } from './user-mutations';
import { actions } from './user-actions';
import { getters } from './user-getters';

// define injection key
export const userKey: InjectionKey<Store<IUserState>> = Symbol();

export const state: IUserState = {
  userInfo: undefined,
  token: '',
  userId: 0,
};

export const userStore = createStore<IUserState>({
  state,
  mutations,
  actions,
  getters,
});

export const userUseStore = function (): Store<IUserState> {
  return baseUseStore(userKey);
};

export type userStoreType = Store<IUserState>;
