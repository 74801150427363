import { MutationTree } from 'vuex';
import { IUserState, IUserInfo } from './user-type';

export const mutations: MutationTree<IUserState> = {
  setToken(state, token: string) {
    state.token = token;
  },
  setUserId(state, userId: number) {
    state.userId = userId;
  },
  remoreLoginData(state) {
    state.token = '';
    state.userId = 0;
    state.userInfo = undefined;
  },
  setUserInfo(state, userInfo: IUserInfo) {
    state.userInfo = userInfo;
  },
};
