import * as fundebug from 'fundebug-javascript';
import FundebugVue from 'fundebug-vue';
import { App } from 'vue';

fundebug.init({
  apikey: 'dd1c40d59dd1543bc6f500942430fb8b938cee5c85ead24941ec9b4434d5c75a',
  metaData: {
    project: 'pbs-cn-pc',
  },
  filters: [
    {
      res: {
        status: /^401$/,
      },
    },
    {
      res: {
        status: /^403$/,
      },
    },
    {
      res: {
        status: /^0$/,
      },
    },
    {
      type: /^unhandledrejection$/,
    },
    {
      message: /Script error/,
    },
    {
      target: {
        tagName: /^IMG$/,
        status: 'inexistence',
      },
    },
    {
      target: {
        tagName: /^IMG$/,
        className: /img-self/,
      },
    },
  ],
  silent: process.env.VUE_APP_ENV === 'prod' ? false : true,
});

export const useFundebug = (app: App<Element>): void => {
  app.use(new FundebugVue(fundebug));
};
