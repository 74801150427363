import CommonLoading from '@/components/common/common-loading/index';

export default {
  // offsetX 偏移量，主要为了loading可以显示在画布的中间
  showLoading(option?: { offsetX: number }) {
    CommonLoading.show(option);
  },
  hideLoading() {
    CommonLoading.hide();
  },
};
