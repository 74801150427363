/* eslint-disable */
import { createApp, ComponentPublicInstance, ComponentOptionsBase, App, createVNode } from 'vue';
import instanceComponent from './common-loading.vue';

const obj: {
  instance?: ComponentPublicInstance<
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  false,
  ComponentOptionsBase<any, any, any, any, any, any, any, any, any, {}>>;
  app?: App<Element>;
  root?: HTMLDivElement;
  unmountInstance(): void;
  initInstance(option?: {offsetX: number;}): void;
  show(option?: {offsetX: number;}): void;
  hide(): void;
} = {
  instance: undefined,
  app: undefined,
  root: undefined,
  unmountInstance() {
    if (this.app) this.app.unmount();
    if (this.root) document.body.removeChild(this.root);
    this.app = undefined
    this.root = undefined
  },
  initInstance(option) {
    this.app = createApp(instanceComponent, {option});
    this.root = document.createElement('div');
    document.body.appendChild(this.root);
    this.instance = this.app.mount(this.root);
  },
  hide() {
    this.unmountInstance()
  },
  show(option) {
    this.unmountInstance()
    this.initInstance(option)
  },
}

export default obj
