import sensorsdata from 'sa-sdk-javascript';

import { ActionTree } from 'vuex';
import { IUserState } from './user-type';
import { RootState } from '../../index-type';
import userApi from '@/apis/user-api';

const TOKEN_KEY = 'token';
const USER_ID_KEY = 'user_id';
const LOGIN_TIME_KEY = 'login_time';

export const actions: ActionTree<IUserState, RootState> = {
  async userLoginFromCache(store): Promise<void> {
    if (store.state.token) {
      return;
    }
    const token = localStorage.getItem(TOKEN_KEY);
    const userId = localStorage.getItem(USER_ID_KEY);
    const loginTime = localStorage.getItem(LOGIN_TIME_KEY);
    const nowTime = new Date().getTime();
    if (!loginTime || !token || !userId) {
      return Promise.reject();
    }
    if (nowTime - +loginTime < 7 * 24 * 60 * 60 * 10000) {
      store.dispatch('userLoginSuccess', { token, user_id: +userId, from: 'cache' });
      return;
    } else {
      return Promise.reject();
    }
  },
  userLoginSuccess(store, data) {
    localStorage.setItem(TOKEN_KEY, data.token);
    localStorage.setItem(USER_ID_KEY, data.user_id);
    if (data.from !== 'cache') {
      localStorage.setItem(LOGIN_TIME_KEY, new Date().getTime().toString());
    }
    store.commit('setToken', data.token);
    store.commit('setUserId', data.user_id);

    // 神策写入用户ID,加上pc是为了区分不同的拍博士项目
    sensorsdata.login(`pc_${data.user_id}`);

    store.dispatch('getUserInfo');
  },

  userLogout(store) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(LOGIN_TIME_KEY);
    store.commit('remoreLoginData');
    location.reload();
    return Promise.resolve();
  },

  // 获取用户信息
  getUserInfo(store) {
    return userApi.getUserInfo().then((res) => {
      store.commit('setUserInfo', res);
      return res;
    });
  },
};
