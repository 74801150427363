// 手机号正则
export const PHONE_REG = /^1\d{10}$/;

// 邮箱正则
export const MAIL_REG = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

export const COLOR_REG = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

// 无中文
export const NO_CN_REG = /^[^\u4e00-\u9fa5]+$/;
