import { App } from 'vue';
import { throttle } from 'lodash';

export const useDirective = (app: App<Element>) => {
  app.directive('animate', {
    beforeMount(el) {
      el.scrollFn = throttle(() => {
        const top = el.getBoundingClientRect().top;
        // 页面可视区域的高度
        const windowHeight = document.documentElement.clientHeight || window.innerHeight;
        const height = el.offsetHeight;
        if (top + height / 2 < windowHeight || top < windowHeight / 2) {
          el.classList.add('father-box');
        }
      }, 100);
      setTimeout(() => {
        el.scrollFn();
      }, 0);
      window.addEventListener('scroll', el.scrollFn);
    },
    unmounted(el) {
      window.removeEventListener('scroll', el.scrollFn);
    },
  });

  app.directive('drag', {
    beforeMount(el, binding) {
      const { mousedownFn, mousemoveFn, mouseupFn } = binding.value;
      const data = {
        initialX: 0,
        initialY: 0,
      };
      // 添加事件
      // 鼠标移动
      const mousemoveFnWrap = function (e: MouseEvent) {
        const currentX = e.clientX;
        const diffX = currentX - data.initialX;
        const currentY = e.clientY;
        const diffY = currentY - data.initialY;
        mousemoveFn &&
          mousemoveFn(e, {
            init_x: data.initialX,
            init_y: data.initialY,
            diff_x: diffX,
            diff_y: diffY,
          });
      };

      // 鼠标按下
      const mousedownFnWrap = function (e: MouseEvent) {
        e.preventDefault();
        // 鼠标初始位置 X
        data.initialX = e.clientX;
        data.initialY = e.clientY;
        // mousemove 事件添加到 document 身上，避免鼠标移动到元素外后，无法继续触发移动事件
        document.addEventListener('mousemove', mousemoveFnWrap);
        document.addEventListener('mouseup', mouseupFnWarp);
        mousedownFn && mousedownFn(e, { init_x: e.clientX, init_y: e.clientY });
      };

      // 鼠标提起
      const mouseupFnWarp = function (e: MouseEvent) {
        e.preventDefault();
        // 清空事件
        document.removeEventListener('mousemove', mousemoveFnWrap);
        document.removeEventListener('mouseup', mouseupFnWarp);
        mouseupFn && mouseupFn(e);
      };

      el.addEventListener('mousedown', mousedownFnWrap);
    },
  });
};
