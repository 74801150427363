import sensorsdata from 'sa-sdk-javascript';

const categoryMap: Record<number, string> = {
  1: 'ID_photo_home',
  2: 'Matting_home',
  3: 'portrait_home',
  4: 'watermark_home',
  5: 'pen_home',
  6: 'clear_home',
};

export default {
  pageview_home() {
    sensorsdata.track('pc_home', {});
    return Promise.resolve();
  },
  pageview_module_home(data: { category_id: number }) {
    sensorsdata.track('pc_function_home', {
      page_title: categoryMap[data.category_id],
    });
    return Promise.resolve();
  },
  pageview_module_preview(data: { category_id: number }) {
    sensorsdata.track('pc_function_preview', {
      page_title: categoryMap[data.category_id],
    });
    return Promise.resolve();
  },
  // 因为点数不足显示充值弹窗
  show_recharge_by_point_lack(data: { category_id: number }) {
    sensorsdata.track('pc_Tips_package', {
      page_title: categoryMap[data.category_id],
    });
    return Promise.resolve();
  },
  // 主动显示充值弹窗
  show_recharge_by_initiative() {
    sensorsdata.track('pc_active_buy', {});
    return Promise.resolve();
  },
};
