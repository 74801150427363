import { ActionTree } from 'vuex';
import { IGlobalState } from './global-type';
import { RootState } from '../../index-type';
import type { ISystemConfig } from '@/types/system';
import systemApi from '@/apis/system-api';

export const actions: ActionTree<IGlobalState, RootState> = {
  // 获取系统配置
  async getSystemConfig(store, params = { refresh: false }): Promise<ISystemConfig> {
    if (!params.refresh && store.state.systemConfig) {
      return store.state.systemConfig;
    }
    const data = await systemApi.getSysConfig();
    store.commit('setSystemConfig', data);
    return data;
  },
};
