import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from 'vue';
import { IGlobalState } from './global-type';

import { mutations } from './global-mutations';
import { actions } from './global-actions';
import { getters } from './global-getters';

// define injection key
export const globalKey: InjectionKey<Store<IGlobalState>> = Symbol();

export const state: IGlobalState = {
  systemConfig: undefined,
  loginDialog: {
    show: false,
    page: 1,
  },
  rechargeDialog: {
    show: false,
  },
};

export const globalStore = createStore<IGlobalState>({
  state,
  mutations,
  actions,
  getters,
});

export const globalUseStore = function (): Store<IGlobalState> {
  return baseUseStore(globalKey);
};

export type globalStoreType = Store<IGlobalState>;
