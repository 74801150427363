import { createApp } from 'vue';
import App from './App.vue';
import { useStore } from './store/index';
import router from './router';
import './less/index.less';
import './less/animate.css';
import userService from './services/user-service';
import { useDirective } from './directives';
import { useFundebug } from './libs/fundebug';
import './libs/sensors-data.init';

const app = createApp(App);
useFundebug(app);
useStore(app);
useDirective(app);
app.use(router);

userService.login({ openLoginDialog: false }).finally(() => {
  app.mount('#app');
});
