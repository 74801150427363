import { ISystemConfig } from '@/types/system';
import http from '@/utils/http';

export default {
  getSysConfig(): Promise<ISystemConfig> {
    return http.post('/sys/sys_config_get', {});
  },

  uploadFile(formData: FormData): Promise<{ host: string; key: string; url: string }> {
    return http.post('/sys/upload_file', formData, {
      timeout: 60000, // 上传60秒超时
      timeoutErrorMessage: '上传请求超时',
    });
  },
};
